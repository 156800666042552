import React from "react";
import FadeIn from "../../components/FadeIn/FadeIn";
import { projects } from "../../assets/data/projects";
import "./ProjectsSection.css";

const ProjectsSection = () => {
  return (
    <section className="projects-wrapper container-fluid" id="projects-section">
      <div className="row">
        <div className="col-md-5 offset-md-1 projects-title-section">
          <FadeIn>
            <div className="title-wrapper">
              <h2 className="turquoise section-title">Projects</h2>
            </div>
            <p className="section-text">
              I have worked on projects across various domains, including
              healthcare, contract management, e-commerce, and food and
              nutrition. These experiences have provided me with valuable
              insights and expertise in multiple industry verticals, enabling me
              to effectively manage even the most complex projects.
            </p>
          </FadeIn>
        </div>
      </div>
      <div className="projects-grid col-md-10 offset-md-1">
        {projects.map((project) => (
          <a
            href={project.link}
            aria-label={`link to project ${project.name}`}
            target="_blank"
            rel="noopener noreferrer"
            key={project.name}
          >
            <div className="project moving-border">
              <div className={"project-inside " + project.className}>
                <h3 className="project-title">{project.name}</h3>
                <h4 className="project-info">{project.info}</h4>
              </div>
            </div>
          </a>
        ))}
      </div>
    </section>
  );
};

export default ProjectsSection;
